import style from "./dashboard.module.scss";
import {
  Sidebar,
  Message,
  OperationCard,
  Calendar,
  OptionCard,
  DoctorFeedback,
  Feedback,
  Bank,
  QuestionCard,
  TopBar,
} from "../../components";
import useWindowDimensions from "../../helpers/windowWidth";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { BASE_URL } from "../../utils/ApiRoutes";
import { ApiRoutes } from "../../utils/ApiRoutes";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { CheckInput } from "../../components/Input/Input";
import { LangData } from "../../data/LangData";
import { AuthButton } from "../../components/Button/Button";
import { acceptAgreement } from "../../redux/actions";

const Dashboard = () => {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  const [sideBar, setSidebar] = useState(false);
  const { width } = useWindowDimensions();
  const userData = useSelector((state) => state.user.user);
  const [operationData, setOperationData] = useState();
  const [optionData, setOptionData] = useState();
  const [loading, setLoading] = useState(true);
  const [operationStatus, setOperationStatus] = useState(true);
  const [calendarStatus, setCalendarStatus] = useState(true);
  const [messageStatus, setMessageStatus] = useState(true);
  const lang = useSelector((state) => state.settings.setting.lang);
  const dispatch = useDispatch();
  const [isAgreement, setAgreement] = useState(false);
  const [isAccept, setAccept] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("refresh") === "login") {
      window.location.reload();
      localStorage.setItem("refresh", "false");
    }
  }, []);

  useEffect(() => {
    axios.defaults.baseURL = BASE_URL;
    axios.interceptors.request.use(
      async (config) => {
        // const token = await AsyncStorage.getItem('token');

        config.headers["Authorization"] = `Bearer ${userData.token}`;

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }, []);

  const getCalendarData = () => {
    axios
      .get(`${ApiRoutes.auth.user.calendar}`)
      .then((res) => {
        const rawData = JSON.parse(res.data.data);
        const filterData =
          rawData && rawData.length
            ? rawData
                .filter((i) => i.Type !== "Operation")
                .map((item) => {
                  return {
                    year: `${moment(item.Datetime)
                      .format("MMMM YYYY")
                      .replace("June", "Jun")
                      .replace("July", "Jul")
                      .replace("August", "Aug")
                      .replace("September", "Sep")
                      .replace("October", "Oct")
                      .replace("November", "Nov")
                      .replace("December", "Dec")
                      .replace("January", "Jan")
                      .replace("February", "Feb")
                      .replace("March", "Mar")
                      .replace("April", "Apr")}`,
                    day: parseFloat(moment(item.Datetime).format("D")),
                    type: item.Type.toLowerCase(),
                    doctorName:
                      item.Type === "Flight"
                        ? item.DoctorNameSurname
                        : item.DoctorNameSurname,
                    desc: item.OperationName,
                    time: item.Time,
                    title: item.Type === "Hotel" ? item.HotelName : item.Title,
                    date: item.Datetime.split("T")[0],
                  };
                })
            : [];

        setOptionData(filterData);

        if (filterData && !filterData.length) {
          setCalendarStatus(false);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get(`${ApiRoutes.auth.operation.getOperationList}`)
      .then((res) => {
        setOperationData(JSON.parse(res.data.data));

        if (JSON.parse(res.data.data) && !JSON.parse(res.data.data).length) {
          setOperationStatus(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setOperationData([]);
      });

    getCalendarData();
  }, []);

  useEffect(() => {
    axios
      .get(`${ApiRoutes.message.getList}?lang=${lang}`)
      .then(async (res) => {
        if (JSON.parse(res.data.data) && !JSON.parse(res.data.data).length) {
          setMessageStatus(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (
      !operationStatus &&
      !calendarStatus &&
      !messageStatus &&
      userData.TypeId !== 2
    ) {
      navigate("/search");
    }
  }, [operationStatus, calendarStatus, messageStatus]);

  return (
    <>
      {userData.TypeId == 2 ? (
        !userData.isAgreement ? (
          !isAccept ? (
            <>
              <div className={style.agreementPopup}>
                <div className={style.card}>
                  <div className={style.content}>
                    <CheckInput
                      title={LangData[lang].form.isOrtakligi}
                      name={"isOrtakligi"}
                      withDoc={require(`../../assets/doc/${lang}/is_ortakligi_${lang}.pdf`)}
                      single={(e) => setAgreement(!isAgreement)}
                      value={isAgreement}
                    />
                  </div>
                  <div className={style.buttonWrapper}>
                    <AuthButton
                      text={LangData[lang].form.acceptAll}
                      isDisabled={!isAgreement}
                      click={() => {
                        setAccept(true);
                        dispatch(acceptAgreement());
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null
        ) : null
      ) : null}
      <div className={style.dashboard}>
        <Sidebar
          isOpen={sideBar || width > 940}
          closeClick={() => setSidebar(false)}
          searchTrigger={() => {}}
        />
        <TopBar hamburgerClick={() => setSidebar(true)} />

        <div className={style.content}>
          {isOpen && <QuestionCard />}
          <div className={style.dashboardMessageContainer}>
            {width > 940 ? (
              <h5 className={style.dashboardTitle}>Mesajlar</h5>
            ) : null}
            <Message />
          </div>
          <div className={style.row}>
            {operationData && operationData.length ? (
              <OperationCard data={operationData} />
            ) : null}
            <Calendar />
          </div>
          <div className={style.row}>
            {optionData && optionData.length ? (
              <OptionCard data={optionData} />
            ) : null}
            <Feedback />

            {/* <DoctorFeedback
            click={() => {
              setOpen(true);
            }}
          /> */}
          </div>
          <div className={style.row}>
            <Bank />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
