import { HomeFooter, HomeHeader } from "../../components";
import style from "./blog.module.scss";
import Blog1 from "../../assets/img/blog/blog-1.png";
import Blog2 from "../../assets/img/blog/blog-2.png";
import Blog3 from "../../assets/img/blog/blog-3.png";
import { useSelector } from "react-redux";
import { LangData } from "../../data/LangData";
import TitleWrapper from "../../components/Home/TitleWrapper/TitleWrapper";
import { ApiRoutes } from "../../utils/ApiRoutes";
import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

const HeadCard = ({ lang, img, title, text, author, date, slug }) => {
  return (
    <a href={`/blog/${slug}`} className={style.headCard}>
      <img src={img} />
      <div className={style.info}>
        <h2>{title}</h2>
        <p>
          {text
            .replace("<p>", "")
            .replace("</p>", "")
            .replace("[&hellip;]", "")}
        </p>
        <div className={style.bottom}>
          <div className={style.person}>
            <h5>{author}</h5>
            <h6>{moment(date).format("DD.MM.YYYY")}</h6>
          </div>
          <a href={`/blog/${slug}`}>{LangData[lang].landing.blog.readMore}</a>
        </div>
      </div>
    </a>
  );
};

const Card = ({ image, title, text, date, author, lang, slug }) => {
  return (
    <a href={`/blog/${slug}`} className={style.card}>
      <img className={style.cover} src={image} />
      <div className={style.bottomWrapper}>
        <div className={style.textWrapper}>
          <h4>{title}</h4>
          <p>
            {text
              .replace("<p>", "")
              .replace("</p>", "")
              .replace("[&hellip;]", "")}
          </p>
        </div>
        <div className={style.bottom}>
          <div className={style.personWrapper}>
            {/*   <img src={personImage} />*/}
            <h5>{author}</h5>
          </div>
          <p className={style.date}>{moment(date).format("DD.MM.YYYY")}</p>
        </div>
        <a href={`/blog/${slug}`} className={style.readMore}>
          {LangData[lang].landing.blog.readMore}
        </a>
      </div>
    </a>
  );
};

const Blog = () => {
  const [data, setData] = useState();

  const lang = useSelector((state) => state.settings.setting.lang);

  useEffect(() => {
    axios
      .get(`${ApiRoutes.search.blog}`)
      .then((res) => {
        setData(JSON.parse(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log("data", data);

  return (
    <div className={style.blog}>
      <div className={style.wrapper}>
        <HomeHeader />
        {data && data.length ? (
          <div className={style.content}>
            <TitleWrapper
              title={LangData[lang].landing.blog.title}
              subTitle={LangData[lang].landing.blog.subtitle}
            />
            <HeadCard
              title={data[0].title}
              text={data[0].shortdesc}
              lang={lang}
              img={data[0].image}
              author={data[0].writer}
              date={data[0].date}
              slug={data[0].slug}
            />
            <div className={style.cardWrapper}>
              {data.map((item, index) => {
                return (
                  <Card
                    title={item.title}
                    text={item.shortdesc}
                    lang={lang}
                    image={item.image}
                    author={item.writer}
                    date={item.date}
                    slug={item.slug}
                  />
                );
              })}
            </div>
          </div>
        ) : null}
        <HomeFooter />
      </div>
    </div>
  );
};

export default Blog;
