import {
  OperationIcon,
  CountryIcon,
  StarIcon,
  HospitalIcon,
  ResumeIcon,
  FlightIcon,
  LocationIcon,
} from "../../../assets/icon";
import style from "./doctorcard.module.scss";
import { CategoryIcon } from "../../../helpers/CategoryIcon";
import { LangData } from "../../../data/LangData";

const DoctorCard = ({
  id,
  image,
  name,
  operation,
  star,
  country,
  price,
  type,
  resume,
  date,
  time,
  hotel,
  city,
  hospital,
  location,
  icon,
  lang,
  title,
  subTitle,
}) => {
  return (
    <a href={`/doctor/${id}`} className={`${style.doctorCard} ${style[type]}`}>
      <div className={style.imageCard}>
        <img src={image} alt={name} />
      </div>

      <div className={style.card}>
        <div className={style.left}>
          {/* <div className={style.operationCard}>
            {icon.map((item) => {
              return (
                <span>
                  <CategoryIcon id={item} />
                </span>
              );
            })}
          </div> */}

          <h3>
            {title} {name}
          </h3>
          <h4 className={style.subTitle}>{subTitle}</h4>
          {/* <div className={style.starWrapper}>
            {[...new Array(5)].map((item, index) => {
              return <StarIcon type={index < star && "on"} />;
            })}
          </div> */}
          <h6>
            <CountryIcon country={country} /> {country} / {city}
          </h6>
          {type === "list" && <h2 className={style.city}>{city}</h2>}
          {type === "list" && (
            <div className={style.hospital}>
              <HospitalIcon />
              {hospital}
            </div>
          )}
        </div>
        {type === "list" && (
          <div className={style.right}>
            {resume ? (
              <div className={style.resume}>
                <div className={style.resumeTitle}>
                  <ResumeIcon />
                  <span>Resume</span>
                </div>
                <p>{resume}</p>
              </div>
            ) : null}
            {/* <div className={style.location}>
              <div className={style.column}>
                <FlightIcon />
                <div>
                  <span>{location}</span>
                  <span>{time}</span>
                </div>
              </div>
              <div className={style.column}>
                <LocationIcon />
                <div>
                  <span>{hotel}</span>
                  <span>{date}</span>
                </div>
              </div>
            </div> */}
          </div>
        )}

        <div className={style.bottom}>
          {/* <span>€{price}</span> */}
          <span />
          {type === "list" ? (
            <>
              <button>{LangData[lang].operations.more}</button>
              <button>{LangData[lang].doctorDetail.sendMessage}</button>
            </>
          ) : (
            <button>{LangData[lang].operations.viewDetails}</button>
          )}
        </div>
      </div>
    </a>
  );
};

export default DoctorCard;
