import { LinearProgress } from "@mui/material";
import style from "./progress.module.scss";

const LinearProgressBar = () => {
  return (
    <div className={style.wrapper}>
      <LinearProgress size="lg" />
    </div>
  );
};

export default LinearProgressBar;
