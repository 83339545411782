import style from "./blogdetail.module.scss";
import { HomeFooter, HomeHeader } from "../../components";
import Blog1 from "../../assets/img/blog/blog-1.png";
import { useState, useEffect } from "react";
import { ApiRoutes } from "../../utils/ApiRoutes";
import axios from "axios";
import { useLocation } from "react-router-dom";
import moment from "moment";

const BlogDetail = () => {
  const location = useLocation();
  const currentSlug = location.pathname.split("/")[2];
  const [data, setData] = useState();

  useEffect(() => {
    axios
      .get(`${ApiRoutes.search.blog}`)
      .then((res) => {
        setData(
          JSON.parse(res.data.data).filter((i) => i.slug === currentSlug)[0]
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log("data", data);
  return (
    <div className={style.blogDetail}>
      <div className={style.wrapper}>
        <HomeHeader />
        {data ? (
          <div className={style.content}>
            <div className={style.head}>
              <img src={data.image} />
              <div className={style.info}>
                <h1>{data.title}</h1>
                <div className={style.person}>
                  <h5>{data.writer}</h5>
                  <h6>{moment(data.date).format("DD.MM.YYYY")}</h6>
                </div>
              </div>
            </div>
            <div
              className={style.text}
              dangerouslySetInnerHTML={{ __html: data.content }}
            />
          </div>
        ) : null}
        <HomeFooter />
      </div>
    </div>
  );
};

export default BlogDetail;
