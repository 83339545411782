import style from "./doctorRegister.module.scss";
import {
  EmailInput,
  PasswordInput,
  RememberMe,
  TextInput,
  PhoneInput,
  DateInput,
  SelectInput,
  RegionInput,
  CityInput,
  CheckInput,
} from "../../components/Input/Input";
import { AuthTitle } from "../../components/Title/Title";
import { AuthButton } from "../../components/Button/Button";
import { AuthFooter, LangSelection } from "../../components";
import DoctorInfo from "./DoctorInfo";

import LoginImage from "../../assets/img/auth/register.png";
import Logo from "../../assets/img/logo/primary-logo.svg";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { ApiRoutes } from "../../utils/ApiRoutes";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import WhiteLogo from "../../assets/img/logo/white-logo.svg";
import useWindowDimensions from "../../helpers/windowWidth";
import { useEffect, useState } from "react";

import { BASE_URL } from "../../utils/ApiRoutes";
import { LangData } from "../../data/LangData";

const DoctorRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [doctorGuid, setDoctorGuid] = useState("");
  const [step, setStep] = useState("register");
  const lang = useSelector((state) => state.settings.setting.lang);
  const [isLoad, setLoad] = useState(false);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  useEffect(() => {
    axios
      .get(`${ApiRoutes.auth.doctor.guid}?lang=${lang}`)
      .then((res) => {
        setDoctorGuid(res.data.data.split(`"`)[1]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const yesterday = new Date(Date.now() - 86400000);
  const RegisterSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, `${LangData[lang].errors.nameshort}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    workTitle: Yup.string()
      .min(3, `${LangData[lang].errors.nameshort}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    surName: Yup.string()
      .min(2, `${LangData[lang].errors.surnameshort}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    email: Yup.string()
      .email(`${LangData[lang].errors.correctmail}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    phone: Yup.string()
      .matches(phoneRegExp, `${LangData[lang].errors.correctphone}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    birthDate: Yup.date().required(`${LangData[lang].errors.mandatoryfield}`),
    region: Yup.string().required(`${LangData[lang].errors.mandatoryfield}`),
    provice: Yup.string().required(`${LangData[lang].errors.mandatoryfield}`),
    district: Yup.string().required(`${LangData[lang].errors.mandatoryfield}`),
    address: Yup.string().required(`${LangData[lang].errors.mandatoryfield}`),
    workingPlace: Yup.string().required(
      `${LangData[lang].errors.mandatoryfield}`
    ),
    password: Yup.string()
      .min(8, `${LangData[lang].errors.correctpassword}`)
      .required(`${LangData[lang].errors.mandatoryfield}`),
    passwordAgain: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        `${LangData[lang].errors.repeatpassword}`
      )
      .required(`${LangData[lang].errors.mandatoryfield}`),
    isOrtakligi: Yup.boolean().oneOf(
      [true],
      LangData[lang].errors.isOrtakligiError
    ),
  });

  const registerSubmit = (values) => {
    axios
      .post(`${ApiRoutes.auth.user.register}?lang=${lang}`, {
        mail: values.email,
        password: values.password,
        typeId: 2,
        name: values.name,
        surname: values.surName,
        birthDate: `${
          values.birthDate.$D < 9
            ? `0${values.birthDate.$D}`
            : values.birthDate.$D
        }-${
          values.birthDate.$M < 9
            ? `0${values.birthDate.$M + 1}`
            : values.birthDate.$M + 1
        }-${values.birthDate.$y}`,
        gender: parseFloat(values.gender),
        phone: values.phone,
        region: values.region,
        district: values.district,
        provice: values.provice,
        country: values.provice,
        address: values.address,
        workingPlace: values.workingPlace,
        guid: doctorGuid,
        workTitle: values.workTitle,
        zipcode: values.zipcode,
        website: values.website,
        instagram: values.instagram,
      })
      .then(async (res) => {
        console.log(res.data);

        if (res.data.caption === "Success") {
          const userData = await JSON.parse(res.data.data);

          console.log("userTOKEN", userData.token);

          localStorage.removeItem("preRegisterDocto");

          axios.defaults.baseURL = BASE_URL;
          await axios.interceptors.request.use(
            async (config) => {
              // const token = await AsyncStorage.getItem('token');
              config.headers["Authorization"] = `Bearer ${userData.token}`;
              return config;
            },
            (error) => {
              return Promise.reject(error);
            }
          );

          await localStorage.setItem("preRegisterDoctor", doctorGuid);
          await localStorage.setItem("token", userData.token);
          await setStep("info");
          await setLoad(false);

          // dispatch(
          //   userLogin({
          //     token: userData.token,
          //     userData: userData.account,
          //   })
          // );

          //   navigate('/dashboard');
        }
      })
      .catch((err) => {
        console.log(err);
        setLoad(false);
      });
  };
  return (
    <div className={style.register}>
      <img
        className={style.logo}
        src={width < 940 ? WhiteLogo : Logo}
        alt="primary-logo"
      />
      <div
        style={{
          position: "fixed",
          right: "30px",
          bottom: "30px",
        }}
      >
        <LangSelection />
      </div>
      {doctorGuid && (
        <>
          {step === "register" ? (
            <div className={style.content}>
              <div className={style.wrapper}>
                <AuthTitle title={LangData[lang].register.doctorRegister} />
                <Formik
                  initialValues={{
                    name: "",
                    surName: "",
                    gender: 0,
                    email: "",
                    phone: "",
                    birthDate: "",
                    region: "",
                    provice: "",
                    district: "",
                    address: "",
                    workingPlace: "",
                    password: "",
                    passwordAgain: "",
                    workTitle: "",
                    instagram: "",
                    website: "",
                    isOrtakligi: false,
                  }}
                  validationSchema={RegisterSchema}
                  onSubmit={(values) => {
                    console.log(values);
                    registerSubmit(values);
                    setLoad(true);
                  }}

                  // innerRef={formikRef}
                >
                  {({
                    errors,
                    touched,
                    values,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <Form id="doctorRegisterForm" onSubmit={handleSubmit}>
                      <div className={style.inputWrapper}>
                        {/* <TextInput
                title={'Username'}
                placeholder="Kullanıcı adı giriniz."
              /> */}
                        <div className={style.column}>
                          <TextInput
                            name={"name"}
                            value={values.name}
                            title={LangData[lang].form.name}
                            placeholder={LangData[lang].form.namePlaceholder}
                          />
                          {errors.name && touched.name ? (
                            <span>{errors.name}</span>
                          ) : null}
                          <TextInput
                            name={"surName"}
                            value={values.surName}
                            title={LangData[lang].form.surname}
                            placeholder={LangData[lang].form.surnamePlaceholder}
                          />
                          {errors.surName && touched.surName ? (
                            <span>{errors.surName}</span>
                          ) : null}
                          <SelectInput
                            name={"workTitle"}
                            value={values.workTitle}
                            title={LangData[lang].form.title}
                            placeholder={LangData[lang].form.titlePlaceholder}
                            doctor
                            valueData={[
                              {
                                name: LangData[lang].form.titlePlaceholder,
                                value: "",
                              },
                              ...LangData[lang].form.doctorTitle.map((item) => {
                                return {
                                  name: item.name,
                                  value: item.value,
                                };
                              }),
                            ]}
                          />
                          {errors.workTitle && touched.workTitle ? (
                            <span>{errors.workTitle}</span>
                          ) : null}
                          <SelectInput
                            name={"gender"}
                            value={values.gender}
                            title={LangData[lang].form.gender}
                            placeholder={LangData[lang].form.genderPlaceholder}
                            valueData={[
                              {
                                name: LangData[lang].form.man,
                                value: 0,
                              },
                              {
                                name: LangData[lang].form.woman,
                                value: 1,
                              },
                              {
                                name: LangData[lang].form.divers,
                                value: 2,
                              },
                            ]}
                          />

                          <EmailInput name={"email"} value={values.email} />
                          {errors.email && touched.email ? (
                            <span>{errors.email}</span>
                          ) : null}
                          <PhoneInput name={"phone"} value={values.phone} />
                          {errors.phone && touched.phone ? (
                            <span>{errors.phone}</span>
                          ) : null}
                          <DateInput
                            date={values.birthDate}
                            name={"birthDate"}
                            value={values.birthDate}
                            change={(e) => {
                              setFieldValue("birthDate", e);
                              console.log(e);
                            }}
                          />
                          {errors.birthDate && touched.birthDate ? (
                            <span>{errors.birthDate}</span>
                          ) : null}
                          <TextInput
                            name={"instagram"}
                            value={values.instagram}
                            title={LangData[lang].form.instagram}
                            placeholder={
                              LangData[lang].form.instagramPlaceholder
                            }
                          />
                          {errors.instagram && touched.instagram ? (
                            <span>{errors.instagram}</span>
                          ) : null}
                          <TextInput
                            name={"website"}
                            value={values.website}
                            title={LangData[lang].form.website}
                            placeholder={LangData[lang].form.websitePlaceholder}
                          />
                          {errors.website && touched.website ? (
                            <span>{errors.website}</span>
                          ) : null}
                        </div>
                        <div className={style.column}>
                          <RegionInput
                            name={"region"}
                            value={values.region}
                            title={LangData[lang].form.region}
                            placeholder={LangData[lang].form.regionPlaceholder}
                          />
                          {errors.city && touched.city ? (
                            <span>{errors.city}</span>
                          ) : null}
                          <CityInput
                            name={"provice"}
                            value={values.provice}
                            title={LangData[lang].form.province}
                            placeholder={
                              LangData[lang].form.provincePlaceholder
                            }
                            data={values.region}
                          />
                          {errors.provice && touched.provice ? (
                            <span>{errors.provice}</span>
                          ) : null}

                          <TextInput
                            name={"district"}
                            value={values.district}
                            title={LangData[lang].form.district}
                            placeholder={
                              LangData[lang].form.districtPlaceholder
                            }
                          />
                          {errors.district && touched.district ? (
                            <span>{errors.district}</span>
                          ) : null}

                          <TextInput
                            name={"address"}
                            value={values.address}
                            title={LangData[lang].form.address}
                            placeholder={LangData[lang].form.addressPlaceholder}
                          />
                          {errors.address && touched.address ? (
                            <span>{errors.address}</span>
                          ) : null}
                          <TextInput
                            name={"zipcode"}
                            value={values.zipcode}
                            title={LangData[lang].form.zipcode}
                            placeholder={LangData[lang].form.zipcodePlaceholder}
                          />
                          {errors.zipcode && touched.zipcode ? (
                            <span>{errors.zipcode}</span>
                          ) : null}
                          <TextInput
                            name={"workingPlace"}
                            value={values.workingPlace}
                            title={LangData[lang].form.workingPlace}
                            placeholder={
                              LangData[lang].form.workingPlacePlaceholder
                            }
                          />
                          {errors.workingPlace && touched.workingPlace ? (
                            <span>{errors.workingPlace}</span>
                          ) : null}
                          <PasswordInput
                            value={values.password}
                            name="password"
                          />
                          {errors.password && touched.password ? (
                            <span>{errors.password}</span>
                          ) : null}
                          <PasswordInput
                            value={values.passwordAgain}
                            name="passwordAgain"
                            again
                          />
                          {errors.passwordAgain && touched.passwordAgain ? (
                            <span>{errors.passwordAgain}</span>
                          ) : null}
                          <CheckInput
                            name={"isOrtakligi"}
                            title={LangData[lang].form.isOrtakligi}
                            value={values.isOrtakligi}
                            withDoc={require(`../../assets/doc/${lang}/is_ortakligi_${lang}.pdf`)}
                          />
                          {errors.isOrtakligi && touched.isOrtakligi ? (
                            <span>{errors.isOrtakligi}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className={style.buttonWrapper}>
                        <AuthButton
                          text={LangData[lang].register.title}
                          type={"submit"}
                          isLoad={isLoad}
                        />
                        <div className={style.login}>
                          {LangData[lang].form.doYouHaveAccount}{" "}
                          <a href="/login">{LangData[lang].login.title}</a>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          ) : (
            <DoctorInfo doctorId={doctorGuid} />
          )}
        </>
      )}
      <AuthFooter />
    </div>
  );
};

export default DoctorRegister;
