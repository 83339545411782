import style from "./filterarea.module.scss";
import Selection from "../Selection/Selection";
import { FilterClearIcon } from "../../../assets/icon";
import DateInput from "../DateInput/DateInput";
import DropDown from "../DropDown/DropDown";
import { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { ApiRoutes } from "../../../utils/ApiRoutes";
import { useSelector } from "react-redux";
// import cities from "cities.json";
import countries from "../../../../node_modules/countries-list/dist/countries.emoji.json";
import { TurkeyCity } from "../../../data/TurkeyCityData";
import { LangData } from "../../../data/LangData";

const bodyData = (lang) => {
  return [
    {
      Name: LangData[lang].search.headArea,
      Id: 1,
    },
    {
      Name: LangData[lang].search.upperArea,
      Id: 2,
    },
    {
      Name: LangData[lang].search.bottomArea,
      Id: 3,
    },
    {
      Name: LangData[lang].search.handArea,
      Id: 4,
    },
    {
      Name: LangData[lang].search.legArea,
      Id: 5,
    },
  ];
};

const FilterArea = ({
  selectedOperation,
  changeOperation,
  selectedBody,
  changeBody,
  selectedGender,
  changeGender,
  selectedCountry,
  changeCountry,
  selectedCity,
  changeCity,
  close,
  resetClick,
}) => {
  const lang = useSelector((state) => state.settings.setting.lang);
  const location = useLocation();
  const searchWord = new URLSearchParams(location.search);

  const [operationData, setOperationData] = useState();

  useEffect(() => {
    if (selectedBody && selectedBody.length) {
      axios
        .post(
          `${ApiRoutes.search.operation.getByCategories}?lang=${lang}`,
          selectedBody
        )
        .then((res) => {
          if (res.data.caption === "Success") {
            setOperationData(JSON.parse(res.data.data));
          }
        })
        .catch((err) => {
          setOperationData();
          console.log(err);
        });
    }
  }, [selectedBody]);

  var countriesList = Object.keys(countries).map((key) => [
    key,
    countries[key],
  ]);

  return (
    <>
      <div onClick={() => close()} className={style.shadowBack} />

      <div className={style.filterArea}>
        {selectedBody.length === 5 &&
        !selectedOperation.length &&
        selectedGender.length === 2 &&
        !selectedCountry.length &&
        !selectedCity.length ? null : (
          <button onClick={resetClick} className={style.resetFilter}>
            Filtreyi Sıfırla
          </button>
        )}
        {/* <div className={style.filterTop}>
        <div className={style.title}>
          <h5>Filter</h5>
          <button>Clear All</button>
        </div>
        <div className={style.buttonWrapper}>
          <button>
            Ventral <FilterClearIcon />
          </button>
          <button>
            Man <FilterClearIcon />
          </button>
          <button>
            Eyebrow <FilterClearIcon />
          </button>
        </div>
      </div> */}

        <Selection
          selected={selectedBody}
          title={LangData[lang].search.bodyArea}
          selection={bodyData(lang)}
          change={(e) => {
            changeBody(e);
          }}
        />

        {operationData && operationData.length ? (
          <Selection
            selected={selectedOperation}
            title={LangData[lang].operations.title}
            selection={operationData}
            change={(e) => {
              changeOperation(e);
            }}
          />
        ) : null}
        <Selection
          selected={selectedGender}
          title={LangData[lang].search.gender}
          selection={[
            {
              Id: 0,
              Name: "Male",
            },
            {
              Id: 1,
              Name: "Female",
            },
          ]}
          change={(e) => {
            changeGender(e);
          }}
        />
        {/* <Selection title={"Gender"} selection={["Man", "Woman"]} /> */}
        <div className={style.wrapper}>
          {/* <DateInput title={"Date of Birth"} /> */}
          {/* <DateInput title={LangData[lang].search.surgeryDate} /> */}
          <DropDown
            change={(e) => changeCountry(e)}
            selected={selectedCountry}
            title={LangData[lang].search.country}
            data={[
              ...countriesList.filter((i) => i[0] === "DE" || i[0] === "TR"),
              ...countriesList.filter((i) => i[0] !== "DE" || i[0] !== "TR"),
            ]}
            country
            selectedCountry={selectedCountry}
          />
          <DropDown
            selected={selectedCity}
            change={(e) => changeCity(e)}
            selectedCountry={selectedCountry}
            title={LangData[lang].search.city}
          />
        </div>
      </div>
    </>
  );
};

export default FilterArea;
