import {
  NotificationIcon,
  SearchIcon,
  SettingsIcon,
  SignOutIcon,
} from "../../assets/icon";
import style from "./header.module.scss";
import Logo from "../../assets/img/logo/primary-logo.svg";
import { HamburgerIcon, HeaderArrow } from "../../assets/icon";
import useWindowDimensions from "../../helpers/windowWidth";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { ApiRoutes } from "../../utils/ApiRoutes";
import { useSelector, useDispatch } from "react-redux";
import EmptyPhoto from "../../assets/img/empty-photo.png";
import LangSelection from "../LangSelection/LangSelection";
import { LangData } from "../../data/LangData";
import { signOut } from "../../redux/actions";

const Header = ({
  hamburgerClick,
  doctorData,
  redirect,
  doctorDetail,
  searchTrigger,
}) => {
  const { width } = useWindowDimensions();
  const lang = useSelector((state) => state.settings.setting.lang);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentPath = location.pathname;
  const [userData, setUserdata] = useState();
  const hideArr = ["/dashboard", "/search-body", "/search"];
  const titleArr = [
    {
      path: "/profile",
      title: "Profile",
    },
    {
      path: "/message",
      title: "Message",
    },
    {
      path: "/operations",
      title: "Operations",
    },
    {
      path: "/calendar",
      title: "Calendar",
    },
  ];
  const [word, setWord] = useState("");
  const [isSearched, setSearched] = useState(false);

  useEffect(() => {
    const searchWord = new URLSearchParams(location.search);
    setWord(searchWord.get("keyword"));
  }, []);

  // const searchDoctor = (val) => {
  //   axios
  //     .post(
  //       `${ApiRoutes.search.doctor.filterDoctor}?search=${val}&lang=${lang}`
  //     )
  //     .then((res) => {
  //       if (res.data.caption === "Success") {
  //         doctorData(JSON.parse(res.data.data));
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   const searchWord = new URLSearchParams(location.search);

  //   if (searchWord && searchWord.get("keyword")) {
  //     setSearched(true);
  //   }
  // }, []);

  // useEffect(() => {
  //   const searchWord = new URLSearchParams(location.search);
  //   if (searchWord.get("keyword") && isSearched) {
  //     setWord(searchWord.get("keyword"));

  //     searchDoctor(searchWord.get("keyword"));
  //     setTimeout(() => {
  //       setSearched(false);
  //     }, 2000);
  //   }
  // }, [isSearched]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate(`/search?keyword=${searchTrigger ? word : ""}`);
      setSearched(true);
      if (searchTrigger) {
        searchTrigger(word);
      }
    }
  };

  useEffect(() => {
    axios
      .get(`${ApiRoutes.auth.user.getProfile}?lang=${lang}`)
      .then((res) => {
        setUserdata(JSON.parse(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className={style.header}>
        <a className={style.logo} href="/dashboard">
          <img src={Logo} alt="logo" />
        </a>
        <label>
          <input
            onChange={(e) => setWord(e.target.value)}
            type={"text"}
            placeholder={LangData[lang].search.title}
            value={word}
            onKeyDown={handleKeyDown}
          />
          <button
            onClick={() => {
              // if (word) {
              //   searchDoctor(word);
              // }

              if (searchTrigger) {
                searchTrigger(word);
              }
              navigate(`/search?keyword=${word ? word : ""}`);
            }}
          >
            <SearchIcon />
          </button>
        </label>
        <div className={style.right}>
          <div className={style.userCard}>
            {userData && userData.Name ? (
              <img
                src={userData.Picture ? userData.Picture : EmptyPhoto}
                alt="person"
              />
            ) : null}
            {width < 940 ? (
              <button onClick={hamburgerClick}>
                <HamburgerIcon />
              </button>
            ) : null}
            {userData && userData.Name ? (
              <div className={style.info}>
                <h6>{LangData[lang].search.welcome},</h6>
                <h4>{userData.Name + " " + userData.Surname}</h4>
              </div>
            ) : null}
          </div>
          <div className={style.buttonWrapper}>
            <LangSelection />
            {userData && userData.Name ? (
              <>
                <button className={style.main}>{<NotificationIcon />}</button>
                <button className={style.main}>
                  {<SettingsIcon />}{" "}
                  <div className={style.popupButton}>
                    <button
                      onClick={() => {
                        delete axios.defaults.headers["Authorization"];
                        dispatch(signOut());
                        localStorage.removeItem("token");
                        navigate("/login");
                      }}
                    >
                      <SignOutIcon /> {LangData[lang].sideBar.signOut}
                    </button>
                  </div>
                </button>
              </>
            ) : null}
          </div>
        </div>
      </div>
      {width < 940 && !hideArr.includes(currentPath) && (
        <div className={style.headerBottom}>
          <a href="/dashboard">
            <HeaderArrow />
          </a>
          <span>
            <a href="/dashboard">{LangData[lang].sideBar.dashboard} / </a>{" "}
            {doctorDetail
              ? doctorDetail
              : titleArr.filter((i) => i.path === currentPath)[0].title}
          </span>
        </div>
      )}
    </>
  );
};

export default Header;
